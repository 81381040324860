export const WEBSITE_BASE_URL =
  process.env.NODE_ENV === 'development' ? 'https://ski.lk.expoforum.ru' : 'https://ski.lk.expoforum.ru';
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

export const INTERNAL_DOCUMENTS = {
  AGREEMENT: '/documents/Согласие.pdf',
};

export const EVENTS = {
  OPEN_CONFIRM_MODAL: 'OPEN_CONFIRM_MODAL',
  OPEN_CONFIRM_APPOINTMENT_MODAL: 'OPEN_CONFIRM_APPOINTMENT_MODAL',
  OPEN_VIEW_IMAGE_MODAL: 'OPEN_VIEW_IMAGE_MODAL',
  OPEN_PACKAGE_DETAIL_MODAL: 'OPEN_PACKAGE_DETAIL_MODAL',
  OPEN_FORGOT_PASSWORD_FORM: 'OPEN_FORGOT_PASSWORD_FORM',
  OPEN_CHANGE_PASSWORD_FORM: 'OPEN_CHANGE_PASSWORD_FORM',
  RELOAD_PRODUCTS: 'RELOAD_PRODUCTS',
  OPEN_MEMBERS_MODAL: 'OPEN_MEMBERS_MODAL',
  OPEN_PROGRAM_DETAIL_MODAL: 'OPEN_PROGRAM_DETAIL_MODAL',
  OPEN_COMPANY_MODAL: 'OPEN_COMPANY_MODAL',
  OPEN_QR_CODE_MODAL: 'OPEN_QR_CODE_MODAL',
  OPEN_SCHEDULE_MEETING: 'OPEN_SCHEDULE_MEETING',
  PLAY_NOTIFICATION_SOUND: 'PLAY_NOTIFICATION_SOUND',
  OPEN_IMAGE_CROPPER_MODAL: 'OPEN_IMAGE_CROPPER_MODAL',
  OPEN_VIEW_PDF_MODAL: 'OPEN_VIEW_PDF_MODAL',
  OPEN_SELECT_PROJECT_MODAL: 'OPEN_SELECT_PROJECT_MODAL',
  OPEN_ADD_MEMBER_IN_MEMBER_TABLE: 'OPEN_ADD_MEMBER_IN_MEMBER_TABLE',
};

export const STANDARD_DATE_FORMAT = 'DD-MM-YYYY';
export const STANDARD_DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm:ss';
export const DEFAULT_BIRTH_DAY = '1990-01-01';

export const ENDPOINT = {
  AUTH: {
    BASE: '/auth',
    LOGIN: '/login',
    REGISTRATION: '/registration',
    REFRESH: '/refresh',
    FORGOT_PASSWORD: '/forgot-password',
    CHECK_EMAIL: '/check-email',
  },
  USER: {
    BASE: '/users',
    CURRENT: '/current',
    AVATAR: '/avatar',
    PASSPORT: '/passport',
    PASSPORT_FILE: '/passport-file',
    PASSPORT_DATA: '/passport-data',
    PROJECTS: '/projects',
    PASSWORD: '/password',
    COMPANY: '/company',
    ORDERS: '/orders',
    ARCHIVED_ORDERS: '/archived_orders',
    DOCUMENTS: '/documents',
    MEMBERS: '/members',
  },
  COUNTRIES: {
    BASE: '/countries',
  },
  ROLES: {
    BASE: '/roles',
  },
  PROJECTS: {
    CURRENT: '/current',
    INTERNAL: '/internal',
    BASE: '/projects',
    JOIN: '/join',
    LEAVE: '/leave',
    PACKAGES: '/packages',
    PHOTOS: '/photos',
    SERVICES: '/services',
    VIZITS: '/vizits',
    MEMBERS: '/members',
  },
};

export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export const IMAGE_FILE_TYPES = ['JPG', 'PNG', 'GIF', 'JPEG'];
export const REGEX_E164_PHONE = /^$|^\+[1-9]\d{1,14}$/;
export const REGEX_FULL_NAME = /^[\w\u0400-\u045f]+\s+[\w\u0400-\u045f]+/gi;

export const LOCALIZATION = {
  en_US: 'en_US',
  ru_RU: 'ru_RU',
};

export const MEETING_VALIDATION_TEXT = {
  error_server: {
    [LOCALIZATION.ru_RU]: 'Ошибка сервис. Попробуйте позже',
    [LOCALIZATION.en_US]: 'Service error. Try later',
  },
  not_found_vizit: {
    [LOCALIZATION.ru_RU]: 'У Вас нет визитка',
    [LOCALIZATION.en_US]: 'You do not have a business card',
  },
  not_join_project: {
    [LOCALIZATION.ru_RU]: 'Вы не посетили проект',
    [LOCALIZATION.en_US]: 'You have not joined the project yet',
  },
  validation_error: {
    [LOCALIZATION.ru_RU]: 'Ошибка проверки',
    [LOCALIZATION.en_US]: 'Validation error',
  },
  your_vizit: {
    [LOCALIZATION.ru_RU]: 'Это Ваша визитка',
    [LOCALIZATION.en_US]: 'This is your business card',
  },
  not_found_member_vizit: {
    [LOCALIZATION.ru_RU]: 'Визитка не найдена',
    [LOCALIZATION.en_US]: 'Business card not found',
  },
  not_found_slot: {
    [LOCALIZATION.ru_RU]: 'Слот не найдено',
    [LOCALIZATION.en_US]: 'Slot not found',
  },
  busy_slot: {
    [LOCALIZATION.ru_RU]:
      'Этот пользователь уже запланировал встречу в это время и может не одобрить ваше бронирование. Вы уверены, что хотите продолжить?',
    [LOCALIZATION.en_US]:
      'This user has already scheduled an appointment at this time and may not approve your booking. Are you sure you want to continue?',
  },
  already_meeting_by_day: {
    [LOCALIZATION.ru_RU]: 'В текущий день у вас есть встреча с этим участником.',
    [LOCALIZATION.en_US]: 'On the current day you have a meeting with this participant.',
  },
  already_has_meeting: {
    [LOCALIZATION.ru_RU]: 'В данное время у вас уже запланирована встреча. Вы уверены, что хотите продолжить?',
    [LOCALIZATION.en_US]: 'At this time, you already have a meeting scheduled. Are you sure you want to continue?',
  },
  ok: {
    [LOCALIZATION.ru_RU]: 'Вы можете отправить запрос на встречу.',
    [LOCALIZATION.en_US]: 'You can send a request for a meeting.',
  },
};

export const GENDER_OPTIONS = {
  man: {
    [LOCALIZATION.ru_RU]: 'Мужской',
    [LOCALIZATION.en_US]: 'Man',
  },
  woman: {
    [LOCALIZATION.ru_RU]: 'Женский',
    [LOCALIZATION.en_US]: 'Woman',
  },
  // not_determined: {
  //   [LOCALIZATION.ru_RU]: 'Неизвестный',
  //   [LOCALIZATION.en_US]: 'Unknown',
  // },
};

export const STATUS_ACCREDIATION = {
  null: {
    label: {
      [LOCALIZATION.ru_RU]: 'На проверке',
      [LOCALIZATION.en_US]: 'in process',
    },
    color: 'gray',
  },
  true: {
    label: {
      [LOCALIZATION.ru_RU]: 'Аккредитовано',
      [LOCALIZATION.en_US]: 'approved',
    },
    color: 'green',
  },
  false: {
    label: {
      [LOCALIZATION.ru_RU]: 'Отклонено',
      [LOCALIZATION.en_US]: 'declined',
    },
    color: 'red',
  },
};

export const STATUS_PAYMENT = {
  undetermined: {
    label: {
      [LOCALIZATION.ru_RU]: 'Неоплачен',
      [LOCALIZATION.en_US]: 'Unpaid',
    },
    color: 'gray',
  },
  confirmed: {
    label: {
      [LOCALIZATION.ru_RU]: 'Создан',
      [LOCALIZATION.en_US]: 'Create',
    },
    color: 'gray',
  },
  approved: {
    label: {
      [LOCALIZATION.ru_RU]: 'Подтверждён',
      [LOCALIZATION.en_US]: 'Approved',
    },
    color: 'gray',
  },
  paid: {
    label: {
      [LOCALIZATION.ru_RU]: 'Оплачен',
      [LOCALIZATION.en_US]: 'Paid',
    },
    color: 'green',
  },
  failed: {
    label: {
      [LOCALIZATION.ru_RU]: 'Не найден',
      [LOCALIZATION.en_US]: 'Failed',
    },
    color: 'gray',
  },
};

export const COMPANY_TYPE_OPTIONS = {
  LEGAL: {
    [LOCALIZATION.ru_RU]: 'Юридическое лицо',
    [LOCALIZATION.en_US]: 'Legal',
  },
  NONRESIDENT: {
    [LOCALIZATION.ru_RU]: 'Не резидент',
    [LOCALIZATION.en_US]: 'Non-resident',
  },
  BRANCH: {
    [LOCALIZATION.ru_RU]: 'Обособленное подразделение',
    [LOCALIZATION.en_US]: 'Branch',
  },
  PERSON: {
    [LOCALIZATION.ru_RU]: 'Физическое лицо',
    [LOCALIZATION.en_US]: 'Person',
  },
};

export const PAYMENT_METHODS = {
  bankCard: {
    label: {
      [LOCALIZATION.ru_RU]: 'Оплата банковской картой',
      [LOCALIZATION.en_US]: 'Pay by card',
    },
    value: 'order_by_card',
  },
  bankAccount: {
    label: {
      [LOCALIZATION.ru_RU]: 'Оплата по счёту',
      [LOCALIZATION.en_US]: 'Pay by invoice',
    },
    value: 'order_by_company',
  },
};

export const MEETING_STATUS = {
  initial: {
    [LOCALIZATION.ru_RU]: 'Ожидает',
    [LOCALIZATION.en_US]: 'initial',
  },
  declined: {
    [LOCALIZATION.ru_RU]: 'Отклонена',
    [LOCALIZATION.en_US]: 'declined',
  },
  approved: {
    [LOCALIZATION.ru_RU]: 'Подтверждена',
    [LOCALIZATION.en_US]: 'approved',
  },
  hold: {
    [LOCALIZATION.ru_RU]: 'В процессе',
    [LOCALIZATION.en_US]: 'in process',
  },
  done: {
    [LOCALIZATION.ru_RU]: 'Проведена',
    [LOCALIZATION.en_US]: 'done',
  },
};

export const VIZIT_STATUS = {
  initial: {
    [LOCALIZATION.ru_RU]: 'Ожидает решения',
    [LOCALIZATION.en_US]: 'initial',
  },
  declined: {
    [LOCALIZATION.ru_RU]: 'Отклонена',
    [LOCALIZATION.en_US]: 'declined',
  },
  approved: {
    [LOCALIZATION.ru_RU]: 'Подтверждена',
    [LOCALIZATION.en_US]: 'approved',
  },
};

export const DOCUMENT_TYPES = {
  AVATAR: 'photo',
  PASSPORT: 'international_passport',
  CONFIRMATION_LETTER: 'confirmation_letter',
  CONSENT_PERSONAL_DATA: 'document_consent_personal_data',
};

export const CONSENT_PERSONAL_DATA_NAME = 'Согласие на обработку персональных данных';

export const LANGUAGES = [
  {
    lang: LOCALIZATION.en_US,
    name: 'EN',
    flag: '/flags/united-kingdom.svg',
  },
  {
    lang: LOCALIZATION.ru_RU,
    name: 'RU',
    flag: '/flags/russia.svg',
  },
];

export const DEFAULT_UTC_OFFSET = 3;
export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

export const PROJECT_SETTINGS = {
  TYPE_BDK: {
    WITH_TABLE: 'with_table',
    WITHOUT_TABLE: 'without_table',
  },
};

export const ORDER_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const SCHEDULE_TYPES = {
  ARRIVAL: 'arrival',
  DEPARTURE: 'departure',
};

export const TEXT = {
  [LOCALIZATION.en_US]: {
    DETAIL: 'Detail',
    CUSTOMIZE: 'customize',
    VERIFIED: 'verified',
    RESEND: 'Resend',
    RESEND_EMAIL: 'Resend email',
    VERIFY_EMAIL: 'Verify email, please!',
    LEFT_MENU_PROJECTS: 'Projects',
    BASKET: 'Baskets',
    LEFT_MENU_CALENDARS: 'Calendars',
    SUPPORT_BUTTON: 'Support',
    LOGOUT_BUTTON: 'Log out',
    CHANGE_LANGUAGE_BUTTON: 'Change',
    MAIN_PAGE: 'Main page',
    EVENT_PAGE: 'Event page',
    WELCOME: 'Welcome!',
    JOINED: 'Joined',
    JOIN: 'Join',
    UNTIL_START: 'Start after',
    DAYS: 'days',
    DATE: 'Date',
    TICKET: 'Ticket',
    MY_TICKET: 'My ticket',
    DOWNLOAD_TICKET: 'Download ticket',
    ORDERS: 'Orders',
    ORDER: 'Order',
    ACCREDITATION: 'Registration',
    ACCREDITATION_VEHICLE: 'Vehicle Registration',
    FULLNAME: 'Full name',
    FULLNAME_VIZIT: 'How should I contact you?',
    FULLNAME_VIZIT_DESCRIPTION: 'How should I contact you? (this inscription will be displayed in the general catalog)',
    LAST_NAME: 'Last name',
    FIRST_NAME: 'First name',
    MIDDLE_NAME: 'Middle name',
    FULLNAME_OF_EMPLOYEES: 'Full name',
    DATE_OF_BIRTH: 'Date of birth',
    GENDER: 'Gender',
    CITIZENSHIP: 'Citizenship',
    PASSPORT_NUMBER: 'Passport number',
    ISSUED_BY: 'Issued by',
    SAVE_CHANGES: 'Save changes',
    SHOW_PACKAGE_DETAILS: 'Show package details',
    PURCHASE_SERVICE_PACKAGE: 'Purchase services and packages',
    PLACE_OF_LIVING: 'Address',
    ADD_MEMBER: 'Add member',
    ADD_MEMBER_IN_TAB_ACCREDAITION: 'Add member in tab Accredaition',
    ADD_COMPANY: 'Add company',
    ADD_ORDER: 'Add order',
    PROFILE: 'Profile',
    PASSPORT_DATA: 'Passport data',
    NUMBER_TELEPHONE: 'Number telephone',
    FROM: 'from',
    ABOUT_THE_EVENT: 'About the event:',
    ARCHIVED_ORDERS: 'Archived orders',
    ARCHIVED: 'Archived',
    NO_ORDERS: 'No orders at the moment',
    EMPTY_BASKET: 'Basket is empty',
    COMPANY: 'Company',
    NAME_COMPANY: 'Name',
    ADDRESS_COMPANY: 'Legal address',
    SITE_COMPANY: 'Site',
    CONTACT_PERSON: 'Contact person',
    TYPE_COMPANY: 'Type company',
    INN: 'INN',
    FULLNAME_COMPANY: 'Fullname',
    COUNTRY_COMPANY: 'Country',
    TELEPHONE_COMPANY: 'Work phone',
    TELEPHONE_VIZIT: 'Contact phone number',
    BDK: 'Business Contact Exchange',
    TELEPHONE_VIZIT_DESCRIPTION:
      'Contact phone number (which will be displayed in the general directory of companies).',
    EMAIL_COMPANY: 'Email',
    KPP: 'KPP',
    AUTHORIZATION: 'Authorization',
    PERSONAL_AREA: 'Personal area',
    REG_ON_EVENT: 'Registration on event',
    FORGOT_PASSWORD: 'Forgot your password',
    READY: 'Ready',
    PASSWORD: 'Password',
    REPEAT_PASSWORD: 'Repeat Password',
    PROCEED: 'Proceed',
    ALREADY_HAVE_ACCOUNT: 'I already have an account',
    REGISTRATION: 'Registration',
    REGISTRATION_FOR_THE_EVENT: 'Registration for the event',
    CREATE_PASSWORD: 'Create a password',
    RESET_PASSWORD: 'Reset the password',
    EVENT: 'Event',
    EVENTS: 'Events',
    EVENTSS: 'Events',
    CHANGE_PASSWORD: 'Change password',
    YOUR_PASSWORD: 'Your password',
    NEW_PASSWORD: 'New password',
    NEW_PASSWORD_REPEAT: 'New password repeat',
    CANCEL: 'Cancel',
    OK: 'OK',
    ACCEPT: 'Got it',
    QUANTITY: 'Quantity',
    TOTAL: 'Total',
    TOTAL_AMOUNT: 'Total amount',
    PAY: 'pay',
    AGREE_WITH_TERMS: 'Agree with the terms',
    TERMS_OF_USE_OF_THE_TRADING_PLATFORM: 'Terms of use of the trading platform',
    NUMBER_ORDER: 'Number',
    IMPOSSIBILITY_OF_RECOGNITION: 'Impossibility of recognition',
    PASSPORT_PROCESSING: 'Passport processing',
    SCANNING_PASSPORT: 'The uploaded data from the passport is being processed',
    WAIT_FEW_SECONDS: 'Please wait a few seconds',
    UPLOAD_SCAN_IDENTITY_DOCUMENT: 'Please upload a scan of an identity document',
    SINGLE_OR_BULK_UPLOAD_SUPPORT: 'Single or bulk upload support',
    UPLOAD_PASSPORT: 'Upload passport',
    UPLOAD_PASSPORT_COMPLETE_DATA: 'Upload a passport to fulfill the fields',
    DURING_SCANNING: 'In process of scanning',
    NO_PROJECTS: 'In the selected period, no projects',
    CONFIRM: 'Confirm',
    PARTICIPATE_PROJECT: 'Do you want to participate in this project?',
    CULTURAL_PROGRAM_AVAILABLE: 'Cultural program available',
    ARE_YOU_SURE: 'Are you sure you want to do this action?',
    CONTINUE: 'Continue',
    RETURN_TO_LIST: 'Return',
    FREE: 'Free',
    HOW_WE_CAN_HELP: 'How we can help?',
    NOT_HAVE_COMPANY: "You don't have company information",
    SCOPE_OF_COMPANY: 'Scope of your company',
    TYPE_ACTIVITY_COMPANY: 'Type of activity of your company',
    COMMENT: 'Comment',
    PURPOSE_VISITING: 'Purpose of visiting the exhibition',
    INTERESTED_SECTIONS: 'What sections/topics of the event are you interested in?',
    NOVELTIES: 'What novelties will be presented at the exhibition?',
    INTERESTED_PROGRAM: 'What event in the business program are you interested in / planning to attend?',
    RECOGNIZE_EVENT_METHOD: 'How did you hear about this event? Where did you see the advertisement?',
    ALLOWED_RECEIVE_NEWS: 'I agree to receive news',
    ALLOWED_PERSONAL_DATA: 'I agree to the processing of personal data',
    JOB_STATUS: 'Your job status',
    EXTENT_COMMERCIAL_DECISIONS: 'To what extent are you empowered to make commercial decisions?',
    AMOUNT_POTENTIAL_PURCHASES: 'Specify the amount of potential purchases at the event',
    EXCHANGE_OF_BUSINESS_CONTACTS: 'Exchange of business contacts',
    NETWORKING: 'Exchange of business contacts',
    MY_BUSINESS_CARD: 'My business card',
    CALENDAR: 'Calendar',
    I_WANT_TO_CHAT: 'I want to chat',
    ABOUT_THE_EXCHANGE: 'Exchange of business contacts',
    UPLOAD: 'Upload',
    UPLOAD_LOGO: 'Upload logo',
    FILE_NOT_MORE_15MB: 'The file must be no more than 15 MB',
    COMPANY_NAME: 'Organization name',
    REGION: 'Region',
    EMAIL_VIZIT: 'Contact email',
    EMAIL_VIZIT_DESRIPTION: 'Contact email (which will be displayed in the general directory).',
    WEB_ADDRESS: 'Website address',
    FIELD_OF_ACTIVITY: 'Field of activity',
    OFFERED_PRODUCT_OR_SERVICES: 'Offered Products / Services',
    PLEASE_TELL_ABOUT_COMPANY: 'Please tell us about your company',
    MEMBERS: 'Members',
    SHOW_MEMBERS: 'Show members',
    EMAIL: 'Email',
    JOB_TITLE: 'Position',
    PLEASE_TELL_ABOUT_YOURSELF: 'Purpose of participation',
    TABLE_NUMBER: 'Table number',
    TABLE: 'Table',
    CONVENIENT_SLOT: 'Convenient slot',
    INDUSTRY: 'Industry',
    KEYWORDS: 'Keywords',
    NO_DESCRIPTION: 'No description',
    RESET: 'Reset',
    SEARCH: 'Search',
    SEARCH_EMAIL: 'Search E-mail',
    COMPANIES_DIRECTORY: 'Catalog companies',
    COMPANIES: 'Companies',
    TABLE_NO: 'Table number',
    I_CONFIRM: 'I confirm',
    CONFIRMED_MEETING: 'Confirm your meeting request',
    BUSINESS_PROGRAM: 'Business Program',
    TIME: 'Time',
    PROGRAM: 'Program',
    PROGRAMS: 'Programs',
    PARTICIPANTS: 'Participants',
    ROOM: 'Room',
    DESCRIPTION: 'Description',
    POSITION: 'Position',
    TYPE: 'Type',
    START: 'Start',
    END: 'End',
    MORE: 'More',
    // EXCHANGE_TEXT:
    //   'The Import Substitution, Subcontracting and Business Contacts Exchange is a large-scale business platform where the largest industrial enterprises and government customers of the Russian Federation will negotiate with new reliable import-substituting manufacturers, suppliers and contractors. Participation for suppliers and customers: free of charge, with mandatory pre-registration to form a schedule of meetings. Registration on the «Exchange of Import Substitution, Subcontracting and Business Contacts»',
    EXCHANGE_TEXT_EXTRA_BDK: `The BDK service offers sophisticated management of your business contacts, but for full access you need to register in your personal account.
    After registration, you will be able to place your business card, not only making an unforgettable impression, but also making information about you available to other users.\nYou can easily view the general catalog of counterparties in our database and schedule meetings at a convenient time for you, keeping detailed records of all important events. Also, exchanging contact information and conducting correspondence within your personal account, you can effectively maintain contacts with partners and clients.\nAnd the diary, which has become your faithful companion, will help you organize all planned events and meetings in a structured form.\nTo use all these advantages of the BDK service, register in your personal account and open up a world of new opportunities.`,
    EXCHANGE_TEXT_EXTRA_NETWORKING: `To participate, you need to fill out a company business card and provide contact information (My Business Card block). When your card receives the "Accredited" status, you can go to the "Sign up for a meeting" section and view available counterparties. To agree on the date and time of a meeting with an interested participant, open a chat with them by clicking on the "Write" button in their business card and discuss all the details. When all the details are clarified, use the "Schedule a meeting" function, where you select the date and time of the meeting, and then send a meeting request to the interlocutor. You can find out the meeting status by opening "Chat" and clicking on the calendar icon in the top menu. Agreed meetings can be viewed in the "Calendar" section (located in the side menu of your Personal Account).`,
    EXCHANGE_TEXT_SMALL:
      '«EF-International LLC is not responsible for failed meetings due to reasons beyond the control of the organizers.»',
    MEETINGS_TO_USER: 'Appointments that you have booked',
    YOUR_MEETINGS: 'Your appointments',
    MEETINGS: 'Appointments',
    MEETING: 'Appointment',
    HOW_TO_KNOW: 'Как узнали о данном мероприятии?',
    BLOCKS_CONGRESS_PROGRAM: 'Какие блоки конгрессной программы вы планируете посетить?',
    INDEX: 'Index',
    CITY: 'City',
    MOB_TEL: 'Mobile tel',
    LEGAL_FORM: 'Организационно-правовая форма',
    THIS_IS_YOUR_VIZIT: 'This is your vizit',
    REGISTER: 'Register',
    OR: 'Or',
    MAIN_USER: 'Main user',
    DURATION: 'Duration',
    MINUTES: 'Minutes',
    TO: 'To',
    ALREADY_STARTED: 'Already started',
    YOU_HAVE_TRANSFER_SERVICE: 'You have a transfer service',
    VISA_FREE_ENTRY: 'Visa-free entry',
    STATUS: 'Status',
    PROJECT_NAME: 'Project name',
    OF: 'Of',
    CHANGE: 'Change',
    DATE_AND_TIME: 'Date and time',
    ALL_PROJECTS: 'Your projects',
    COPY: 'Copy',
    COPY_SHARED_LINK: 'Copy shared link',
    BUSINESS_CARD: 'Business card',
    CHAT: 'Chat',
    CHATS: 'Chats',
    SEARCH_VIZITS: 'Search vizits',
    START_CHAT: 'Start chat',
    SEND: 'Send',
    SCHEDULE_MEETING: 'Schedule meeting',
    SHOW_ALL_MEMBERS: 'Show all members',
    HIDE_ALL_MEMBERS: 'Hide all members',
    AT: 'at',
    ADD: 'Add',
    ADD_SCHEDULE: 'Add schedule',
    EDIT_SCHEDULE: 'Edit schedule',
    ADD_SLOT: 'Add slot',
    START_NETWORKING: 'Start networking',
    BUY_NETWORKING_PACKAGE: ' Purchase services and packages',
    INPUT_CODE: 'Enter code',
    CODE: 'Code',
    ANKETA: 'Questionnaire',
    YOUR_PROFILE: 'Your role',
    CLOSE: 'Close',
    NEXT: 'Next',
    PREVIOUS: 'Previous',
    SUBMIT: 'Submit',
    SELF_REGISTER: 'Self-registration kiosk',
    OPTIONS: 'Options',
    PRINT: 'Print',
    INFORMATION_FILES: 'Information files',
    EXAMPLE: 'Example',
    DISTRIBUTE_ORDERS: 'Distribute orders',
    COUNT: 'Count',
    ROLE: 'Role',
    GENERAL_ORDER: 'General order',
    VIEW_ALL: 'View all',
    NO_DATA: 'No data',
    SUCCESS: 'Success',
    LEFTCOUNT: 'Leftcount',
    NO_MORE_QUOTA: 'No more quota',
    USE_AVATAR: 'Use avatar',
    GROUP_NAME: 'Group name',
    NUMBER_OF_PEOPLE: 'Number of people',
    ARRIVAL: 'Arrival',
    ARRIVAL_DATE: 'Arrival date',
    ARRIVAL_TIME: 'Arrival time',
    MEETING_PLACE: 'Meeting place',
    DEPARTURE: 'Departure',
    DEPARTURE_DATE: 'Departure date',
    DEPARTURE_TIME: 'Departure time',
    DEPARTURE_LOCATION: 'Departure location',
    UPLOADED: 'Uploaded',
    ALLOWED: 'Allowed',
    LIMIT_SIZE: 'Limit size',
    LEAVE_PROJECT: 'Leave project',
    LOCATION: 'Location',
    NAME: 'Name',
    FURTHER: 'Further',
    OFFER: 'Offer',
  },
  [LOCALIZATION.ru_RU]: {
    DETAIL: 'Детали',
    CUSTOMIZE: 'настроить',
    VERIFIED: 'Подтвержден',
    RESEND: 'Отправить',
    BDK: 'Биржа деловых контактов',
    RESEND_EMAIL: 'Отправить письмо повторно',
    VERIFY_EMAIL: 'Подтвердите адрес электронной почты, пожалуйста',
    LEFT_MENU_PROJECTS: 'Все мероприятия',
    BASKET: 'Корзина',
    LEFT_MENU_CALENDARS: 'Календарь',
    SUPPORT_BUTTON: 'Поддержка',
    LOGOUT_BUTTON: 'Выйти',
    CHANGE_LANGUAGE_BUTTON: 'Изменить',
    MAIN_PAGE: 'Главная страница',
    EVENT_PAGE: 'Страница мероприятия',
    WELCOME: 'Добро пожаловать!',
    JOINED: 'Отказаться от участия',
    JOIN: 'Добавить в мой список',
    UNTIL_START: 'До начала осталось',
    DAYS: 'дней',
    DATE: 'Дата',
    TICKET: 'Билет',
    MY_TICKET: 'Мой билет',
    DOWNLOAD_TICKET: 'СКАЧАТЬ БИЛЕТ',
    ORDERS: 'Заказы',
    ORDER: 'Заказ',
    ACCREDITATION: 'Регистрация',
    ACCREDITATION_VEHICLE: 'Регистрация транспортного средства',
    FULLNAME: 'ФИО',
    FULLNAME_VIZIT: 'Как к Вам обращаться?',
    FULLNAME_VIZIT_DESCRIPTION: 'Как к Вам обращаться? (данная надпись будет отображаться в общем каталоге)',
    LAST_NAME: 'Фамилия',
    FIRST_NAME: 'Имя',
    MIDDLE_NAME: 'Отчество',
    FULLNAME_OF_EMPLOYEES: 'ФИО сотрудника',
    DATE_OF_BIRTH: 'Дата рождения',
    GENDER: 'Пол',
    CITIZENSHIP: 'Гражданство',
    PASSPORT_NUMBER: 'Серия и номер паспорта',
    ISSUED_BY: 'Кем выдан',
    SAVE_CHANGES: 'Сохранить изменения',
    SHOW_PACKAGE_DETAILS: 'Показать детали пакета',
    PURCHASE_SERVICE_PACKAGE: 'Покупка пакета услуг',
    PLACE_OF_LIVING: 'Адрес',
    ADD_MEMBER: 'Добавить участника',
    ADD_MEMBER_IN_TAB_ACCREDAITION: 'Добавить участника во вкладке Аккредитация',
    ADD_COMPANY: 'Добавить компанию',
    ADD_ORDER: 'Добавить заказ',
    PROFILE: 'Профиль',
    PASSPORT_DATA: 'Паспортные данные',
    NUMBER_TELEPHONE: 'Моб. тел',
    FROM: 'от',
    ABOUT_THE_EVENT: 'О мероприятии:',
    ARCHIVED_ORDERS: 'Архивированные заказы',
    ARCHIVED: 'Архивированные',
    NO_ORDERS: 'Заказы отсутствуют',
    EMPTY_BASKET: 'Корзина пуста',
    COMPANY: 'Компании',
    NAME_COMPANY: 'Название',
    ADDRESS_COMPANY: 'Юридический адрес',
    SITE_COMPANY: 'Сайт',
    CONTACT_PERSON: 'Контактное лицо',
    TYPE_COMPANY: 'Тип компании',
    INN: 'ИНН',
    FULLNAME_COMPANY: 'Полное название',
    COUNTRY_COMPANY: 'Страна',
    TELEPHONE_COMPANY: 'Рабочий телефон',
    TELEPHONE_VIZIT: 'Контактный телефон',
    TELEPHONE_VIZIT_DESCRIPTION: 'Контактный телефон (который будет отображаться в общем каталоге компаний).',
    EMAIL_COMPANY: 'Почта',
    KPP: 'КПП',
    AUTHORIZATION: 'Авторизация',
    PERSONAL_AREA: 'Личный кабинет',
    REG_ON_EVENT: 'Регистрация на мероприятия',
    FORGOT_PASSWORD: 'Забыли пароль',
    READY: 'Готово',
    PASSWORD: 'Пароль',
    REPEAT_PASSWORD: 'Повторить пароль',
    PROCEED: 'Продолжить',
    ALREADY_HAVE_ACCOUNT: 'Уже есть аккаунт',
    REGISTRATION: 'Регистрация',
    REGISTRATION_FOR_THE_EVENT: 'Регистрация на мероприятие',
    CREATE_PASSWORD: 'Придумайте пароль',
    RESET_PASSWORD: 'Сбросить пароль',
    EVENT: 'событие',
    EVENTSS: 'событий',
    EVENTS: 'события',
    CHANGE_PASSWORD: 'Изменить пароль',
    YOUR_PASSWORD: 'Ваш пароль',
    NEW_PASSWORD: 'Новый пароль',
    NEW_PASSWORD_REPEAT: 'Новый пароль повторить',
    CANCEL: 'Отмена',
    OK: 'ОK',
    ACCEPT: 'Понятно',
    QUANTITY: 'Количество',
    TOTAL: 'Итого',
    TOTAL_AMOUNT: 'Общая сумма',
    PAY: 'Оплатить',
    AGREE_WITH_TERMS: 'Согласен с условиями',
    TERMS_OF_USE_OF_THE_TRADING_PLATFORM: 'и правилами пользования торговой площадкой',
    NUMBER_ORDER: 'Номер',
    IMPOSSIBILITY_OF_RECOGNITION: 'Невозможно распознать',
    PASSPORT_PROCESSING: 'Обработка паспорта',
    SCANNING_PASSPORT: 'Загруженные данные с паспорта проходят обработку',
    WAIT_FEW_SECONDS: 'Пожалуйста, подождите несколько секунд',
    UPLOAD_SCAN_IDENTITY_DOCUMENT: 'Пожалуйста, загрузите скан документа, удостоверяющий личность',
    SINGLE_OR_BULK_UPLOAD_SUPPORT: 'Поддержка одиночной или массовой загрузки',
    UPLOAD_PASSPORT: 'Загрузить паспорт',
    UPLOAD_PASSPORT_COMPLETE_DATA: 'Загрузить паспорт для заполнения данных',
    DURING_SCANNING: 'В процессе сканирования',
    NO_PROJECTS: 'В выбранном периоде, нет проектов',
    CONFIRM: 'Подтвердить',
    PARTICIPATE_PROJECT: 'Вы хотите участвовать в этом проекте?',
    CULTURAL_PROGRAM_AVAILABLE: 'Доступна культурная программа',
    ARE_YOU_SURE: 'Вы уверены, что хотите выполнить это действие?',
    CONTINUE: 'Продолжить',
    RETURN_TO_LIST: 'Вернуться к списку',
    FREE: 'Бесплатно',
    HOW_WE_CAN_HELP: 'Вопросы которые смогут вам помочь',
    NOT_HAVE_COMPANY: 'Заполните информацию о компании в профиле',
    SCOPE_OF_COMPANY: 'Сфера деятельности вашей компании',
    TYPE_ACTIVITY_COMPANY: 'Вид товароборота в вашей компании',
    COMMENT: 'Комментарий',
    PURPOSE_VISITING: 'Цель визита на выставку',
    INTERESTED_SECTIONS: 'Какие разделы/тематики мероприятия Вам интересны?',
    NOVELTIES: 'Какие товары/услуги будут представлены на выставке?',
    INTERESTED_PROGRAM: 'Какое мероприятие в деловой программе вам интересны/планируете посетить?',
    RECOGNIZE_EVENT_METHOD: 'Как узнали о данном мероприятии? Где вы видели рекламу?',
    ALLOWED_RECEIVE_NEWS: 'Согласен получать новости',
    ALLOWED_PERSONAL_DATA: 'Согласен на обработку персональных данных',
    JOB_STATUS: 'Название Вашей должности в организации',
    EXTENT_COMMERCIAL_DECISIONS: 'Оцените Ваши полномочия на принятие коммерческих решений в организации',
    AMOUNT_POTENTIAL_PURCHASES: 'Укажите размер потенциальных закупок на мероприятии',
    EXCHANGE_OF_BUSINESS_CONTACTS: 'Нетворкинг',
    NETWORKING: 'Нетворкинг',
    MY_BUSINESS_CARD: 'Моя визитка',
    CALENDAR: 'Календарь',
    I_WANT_TO_CHAT: 'Записаться на встречу',
    ABOUT_THE_EXCHANGE: 'Нетворкинг',
    UPLOAD: 'Загрузить',
    UPLOAD_LOGO: 'Загрузить логотип',
    FILE_NOT_MORE_15MB: 'Файл должен весить не больше 15 Мб',
    COMPANY_NAME: 'Название организации',
    REGION: 'Регион',
    EMAIL_VIZIT: 'Контактная почта',
    EMAIL_VIZIT_DESRIPTION: 'Контактная почта (которая будет отображаться в общем каталоге).',
    WEB_ADDRESS: 'Адрес сайта',
    FIELD_OF_ACTIVITY: 'Сфера деятельности',
    OFFERED_PRODUCT_OR_SERVICES: 'Предлагаемая продукция / Услуги',
    PLEASE_TELL_ABOUT_COMPANY: 'Пожалуйста, расскажите о вашей компании',
    MEMBERS: 'Участники',
    SHOW_MEMBERS: 'Показать участников',
    EMAIL: 'Электронная почта',
    JOB_TITLE: 'Должность',
    PLEASE_TELL_ABOUT_YOURSELF: 'Цель участия',
    TABLE_NUMBER: 'Выберите номер стола',
    TABLE: 'Стол',
    CONVENIENT_SLOT: 'Выберите удобный слот',
    INDUSTRY: 'Отрасль',
    KEYWORDS: 'Ключевые слова',
    NO_DESCRIPTION: 'нет описания',
    RESET: 'Сбросить',
    SEARCH: 'Поиск',
    SEARCH_EMAIL: 'Поиск E-mail',
    COMPANIES_DIRECTORY: 'Каталог компаний',
    COMPANIES: 'компаний',
    TABLE_NO: 'Стол №',
    I_CONFIRM: 'Подтверждаю',
    CONFIRMED_MEETING: 'Подтвердите ваш запрос на встречу',
    BUSINESS_PROGRAM: 'Конгрессная программа и Расписание программы проекта',
    TIME: 'Время',
    PROGRAM: 'Программ',
    PROGRAMS: 'Программы',
    PARTICIPANTS: 'Участники',
    ROOM: 'Комната',
    DESCRIPTION: 'Описание',
    POSITION: 'Должность',
    TYPE: 'Тип',
    START: 'Начало',
    END: 'Окончание',
    MORE: 'Подробнее',
    // EXCHANGE_TEXT:
    //   '«Биржа импортозамещения, субконтрактинга и деловых контактов» – масштабная бизнес-площадка, на которой крупнейшие промышленные предприятия и госзаказчики РФ проведут переговоры с новыми надежными импортозамещающими производителями, поставщиками и подрядчиками. Участие для поставщиков и заказчиков: бесплатное, по обязательной предварительной регистрации для формирования графика встреч. Регистрация на «Биржу импортозамещения, субконтрактинга и деловых контактов»',
    EXCHANGE_TEXT_EXTRA_NETWORKING: `Для участия Вам нужно заполнить визитную карточку компании и предоставить контактные данные (блок Моя визитка). Когда ваша карточка получит статус "Аккредитовано", Вы сможете перейти в раздел "Записаться на встречу" и посмотреть доступных контрагентов. Чтобы согласовать дату и время встречи с заинтересованным участником, откройте чат с ним, нажав на кнопку "Написать" в его визитной карточке, и обсудите все детали. Когда все детали будут уточнены, воспользуйтесь функцией "Запланировать встречу", где вы выберете дату и время встречи, а затем отправите запрос на встречу собеседнику. Статус встречи можно узнать, открыв "Чат" и нажав на значок календаря в верхнем меню. Согласованные встречи можно посмотреть в разделе "Календарь" (находится в боковом меню вашего Личного кабинета).`,
    EXCHANGE_TEXT_EXTRA_BDK: `Сервис БДК предлагает изысканное управление вашими деловыми контактами, но для полного доступа необходима регистрация в личном кабинете.\nПосле регистрации вы сможете разместить вашу визитную карточку, не только производя незабываемое впечатление, но и делая информацию о вас доступной для других пользователей.\nВы с легкостью просмотрите общий каталог контрагентов, находящихся в нашей базе данных, и назначайте встречи в удобное для вас время, ведя подробный учет по всем важным событиям. Также, обмениваясь контактными данными и ведя переписку внутри личного кабинета, вы сможете эффективно поддерживать связи с партнерами и клиентами.\nИ ежедневник, ставшим вашим верным спутником, поможет вам организовать все запланированные события и встречи в структурированной форме.\nДля пользования всеми этими преимуществами сервиса БДК, зарегистрируйтесь в личном кабинете и откройте мир новых возможностей.`,
    EXCHANGE_TEXT_SMALL:
      '«ООО «ЭФ-Интернэшнл» не несет ответственности за несостоявшиеся встречи по причинам, не зависящим от организаторов.»',
    MEETINGS_TO_USER: 'Встречи, которые были забронированы Вами',
    YOUR_MEETINGS: 'Ваши встречи',
    MEETINGS: 'Встречи',
    MEETING: 'Встреча',
    HOW_TO_KNOW: 'Как узнали о данном мероприятии?',
    BLOCKS_CONGRESS_PROGRAM: 'Какие блоки конгрессной программы вы планируете посетить?',
    INDEX: 'Индекс',
    CITY: 'Город',
    MOB_TEL: 'Моб. тел',
    LEGAL_FORM: 'Организационно-правовая форма',
    THIS_IS_YOUR_VIZIT: 'Это Ваша визитка',
    REGISTER: 'Пройдите регистрацию',
    OR: 'Или',
    MAIN_USER: 'Основной пользователь',
    DURATION: 'Продолжительность',
    MINUTES: 'Минуты',
    TO: 'К',
    ALREADY_STARTED: 'Уже началось',
    YOU_HAVE_TRANSFER_SERVICE: 'Вам доступна услуга трансфера',
    VISA_FREE_ENTRY: 'Безвизовый въезд',
    STATUS: 'Cтатус',
    PROJECT_NAME: 'Название мероприятия',
    OF: 'из',
    CHANGE: 'Изменить',
    DATE_AND_TIME: 'Дата и время',
    ALL_PROJECTS: 'Мои мероприятия',
    COPY: 'Копировать',
    COPY_SHARED_LINK: 'Скопировать общую ссылку',
    BUSINESS_CARD: 'Визитка',
    CHAT: 'Чат',
    CHATS: 'Чаты',
    SEARCH_VIZITS: 'Общий каталог',
    START_CHAT: 'Написать',
    SEND: 'Отправить',
    SCHEDULE_MEETING: 'Запланировать встречу',
    SHOW_ALL_MEMBERS: 'показать всех учасников',
    HIDE_ALL_MEMBERS: 'скрывать всех учасников',
    AT: 'в',
    ADD: 'Добавить',
    ADD_SCHEDULE: 'Добавить расписание',
    EDIT_SCHEDULE: 'Изменить расписание',
    ADD_SLOT: 'Подтвердить слот',
    START_NETWORKING: 'Начать общение',
    BUY_NETWORKING_PACKAGE: 'Покупка пакета услуг',
    INPUT_CODE: 'Введите код',
    CODE: 'Код',
    ANKETA: 'Анкета',
    YOUR_PROFILE: 'Ваша роль',
    CLOSE: 'Закрывать',
    NEXT: 'Следующий',
    PREVIOUS: 'Предыдущий',
    SUBMIT: 'Завершить регистрацию',
    SELF_REGISTER: 'Киоск саморегистрации',
    OPTIONS: 'Параметры',
    PRINT: 'Распечатать',
    INFORMATION_FILES: 'Информационные файлы',
    EXAMPLE: 'Например',
    DISTRIBUTE_ORDERS: 'Распределение заказов',
    COUNT: 'Считать',
    ROLE: 'Роль',
    GENERAL_ORDER: 'Общий заказ',
    VIEW_ALL: 'Посмотреть все',
    NO_DATA: 'Нет данных',
    SUCCESS: 'Успех',
    LEFTCOUNT: 'Левый отсчет',
    NO_MORE_QUOTA: 'Больше нет квоты',
    USE_AVATAR: 'Использовать аватар',
    GROUP_NAME: 'Имя группы',
    NUMBER_OF_PEOPLE: 'Число людей',
    ARRIVAL: 'Прибытие',
    ARRIVAL_DATE: 'Дата прибытия',
    ARRIVAL_TIME: 'Время прибытия',
    MEETING_PLACE: 'Место встречи',
    DEPARTURE: 'Отправление',
    DEPARTURE_DATE: 'Дата отбытия',
    DEPARTURE_TIME: 'Время отправления',
    DEPARTURE_LOCATION: 'Место отправления',
    UPLOADED: 'Загружено',
    ALLOWED: 'Допустимый',
    LIMIT_SIZE: 'Ограничить размер',
    LEAVE_PROJECT: 'Покинуть проект',
    LOCATION: 'Местоположение',
    NAME: 'Название',
    FURTHER: 'Далее',
    OFFER: 'Оферта',
  },
};

export const MESSAGES = {
  [LOCALIZATION.en_US]: {
    CHECK_APPROVE_MEETING:
      'You already have another scheduled meeting at this time. Are you sure that you want to approve this meeting?',
    ERROR_CHECK_LATER: 'Some happened, check again later!',
    MESSAGE_SENT_CODE: 'Send code successfully, please check your email inbox',
    ERROR_MESSAGE_NON_EXIST_EMAIL: 'You have not yet registered in the systems',
    ERROR_MESSAGE_FORGOT_PASSWORD_EMAIL: 'Email did not exist in the system',
    MESSAGE_SENT_PASSWORD_TO_EMAIL: 'Password sent to email',
    PASSWORD_HAS_BEEN_CHANGED: 'Password has been changed',
    YOUR_PASSWORD_NOT_CORRECT: 'Your password is not correct',
    SAVE_SUCCESSFULLY: 'Save successfully',
    AUTHORIZATION_SUCCESSFULLY: 'Authorization successful',
    EMAIL_OR_PASSWORD_INCORRECT: 'Email or password is incorrect, please try again',
    COMPANY_ADDED: 'Company added',
    ADDED_TO_CART: 'Added to cart',
    LEAVE_SUCCESS: 'Leave success',
    SUCCESSFUL_PARTICIPATION: 'Successful Participation',
    SUCCESSFUL_JOIN: 'Successful Join',
    PAYMENT_PROCESS_FAIL: 'Payment processing failed',
    PAYMENT_PROCESS_SUCCESSFUL: 'Payment processing successful',
    PAYMENT_INVOICE_PROCESS_SUCCESSFUL:
      'You can download the invoice for payment on the Orders page. This is a notification when placing an order by invoice',
    ERROR: 'Error',
    DO_NOT_HAVE_TICKET: "You don't have a ticket yet",
    WRONG_DATA: 'Wrong data',
    EMAIL_EXIST: 'Email exists',
    ALREADY_REGISTERED: 'User already registered',
    ALREADY_JOINED_PROJECT: 'User has already joined this project',
    FORCE_EDIT_CONFIRM:
      'You are accredited. You will lose all current accreditations if you want to edit your profile. After editing, you must re-accredit. Are you sure you want to edit your profile?',
    ARE_YOU_SURE: 'Are you sure to do this?',
    PLEASE_UPLOAD_LOGO: 'Please upload logo',
    PLEASE_UPLOAD_AVATAR: 'Please upload avatar',
    CHOOSE_MEETING_SLOT: 'Choose a meeting slot',
    AVAILABLE_FOR_MEETING: 'Available for meeting',
    TIME_AND_PLACE_OF_MEETING: 'Time and place of meeting',
    CONFIRMED_MEETING: 'Your request has been sent',
    SHOW_ALL_PARTICIPANTS: 'Show all participants',
    SLOT_BUSY_ERROR: 'The slot was busy. Choose another',
    NO_QUOTA: 'Visit quota exceeded',
    DO_NOT_HAVE_APPOINTMENTS: "You don't have scheduled appointments",
    DO_NOT_HAVE_USER_VIZIT: "You don't have user vizit",
    DELETE_SUCCESSFULLY: 'Delete successfully',
    LOGIN_WITH_YANDEX_ID: 'Log in with Yandex ID',
    WAIT_UNTIL_ADMIN_SET_ROLE: 'Wait until admin set your role',
    ALERT_REMOVE_USER_FROM_PROJECT: 'Are you sure to remove this user from this project?',
    SHARE_MY_LINK: 'Share',
    VIZIT_NOT_FOUND: 'Business card not found',
    YOU_CAN_ACCESS_AFTER_VERIFYING_YOUR_VIZITKA: 'You can access after verifying your vizitka',
    DOWNLOAD_SUCCESSFULLY: 'Download successfully',
    DOWNLOAD_VCARD: 'VCard',
    ROWS_PER_PAGE: 'Rows per page',
    DELETE: 'Delete',
    LOGIN_WARNING: 'Login will be locked in 30 minutes after 5 attempts',
    LOGIN_ERROR: 'Login is locked in 30 minutes. Try again later',
    NO_MESSAGES: 'No messages',
    TYPE_MESSAGE: 'Type your message here',
    ADDED_TO_CALENDAR: 'Added to calendar',
    HIDE_MEETINGS: 'Hide meetings',
    SHOW_MEETINGS: 'Show meetings',
    SET_UP_WILL_BE_DONE_SOON: 'Setup will be done soon. Try later',
    YOU_HAVE_A_NEW_MESSAGE: 'You have a new message',
    YOU_HAVE_A_NEW_MEETING_BOOKING: 'You have a new meeting booking',
    WARNING_CREATE_VIZIT: 'Create your business card to access to some features',
    GO_TO_BASKET: 'Go to basket',
    CREATE_YOUR_BUSINESS_CARD_TO_BUY_A_PACKAGE:
      'Create your business card. Then wait for confirmation or purchase services and packages',
    WAIT_FOR_BUSINESS_CARD_CONFIRMATION: 'Wait for business card confirmation or purchase services and packages',
    NO_PACKAGES: 'No packages and services at the moment',
    CAPTCHA_ERROR: 'You need to validate captcha again',
    VERIFY_SUCCESSFULLY: 'Verify successfully',
    VERIFY_CODE_IS_INVALID_OR_EXPRIED: 'The code is invalid or has expired',
    REGISTRATION_IS_NOT_AVAILABLE: 'Registration is not available',
    PLEASE_CONTACT_THE_CHECK_IN_DESK: 'please contact the front desk to obtain your ticket',
    ALERT_CONTINUE_FILL_OUT_THE_PAGE: 'Continue filling out or the page will close in {second} seconds',
    YOUR_TICKET_HAS_BEEN_SENT: 'Your ticket has been sent for printing',
    GENERATE_YOUR_TICKET: 'Generate your ticket',
    NO_DATA: 'No data',
    CHOOSE_ONE_OF_BELOW_OPTIONS: 'Choose one of below options',
    I_AM_A_NEW_VISITOR: 'I am a new visitor',
    I_ALREADY_HAVE_REGISTRATION: 'I already have registration',
    ENTER_YOUR_TICKET_NUMBER: 'Enter your ticket number',
    YOUR_TICKET_HAS_BEEN_SENT_WITHOUT_EMAIL: 'Your ticket has been sent for printing',
    YOUR_TICKET_HAS_NOT_FOUND: 'Your ticket has not found',
    ALL_PACKAGES_WERE_DISTRIBUTED: 'All packages were distributed',
    PLEASE_SELECT_A_ROLE: 'Please select a role',
    THIS_WEBSITE_USES_COOKIES:
      'We use cookies and other similar technologies. By using this site you agree to the use of these technologies.',
    FILE_SIZE_IS_TOO_LARGE: 'File size is too large',
    YOUR_YANDEX_ACCOUNT_DOES_NOT_HAVE_EMAIL: 'Your yandex account does not have email. Try another account, please',
    CONFIRM_LEAVE_PROJECT: 'Leaving a project will delete all data associated with that project. Are you sure?',
    ENTER_YOUR_EMAIL: 'Enter your email',
    EVENT_PARTICIPANT: 'Event participant',
    TO_REGISTER_FOR_THE_EVENT: 'To register for the event, you must log in',
    OR_REGISTER_YOUR_ACCOUNT: 'or register in your personal account',
    REGISTER_REACH_LIMIT_NOTIFY_1:
      'Registration via this link is limited. To register in your Personal Account, you can follow the link -',
    REGISTER_REACH_LIMIT_NOTIFY_2: ' and register as a visitor, or contact support ',
    GO_TO_HOME: 'Go to home',
  },
  [LOCALIZATION.ru_RU]: {
    CHECK_APPROVE_MEETING:
      'На это время у вас уже запланирована еще одна встреча. Вы уверены, что хотите одобрить эту встречу?',
    ERROR_CHECK_LATER: 'Что-то произошло, проверьте позже!',
    MESSAGE_SENT_CODE: 'Код отправлен, пожалуйста, проверьте свой почтовый ящик',
    PASSWORD_HAS_BEEN_CHANGED: 'Пароль изменен',
    YOUR_PASSWORD_NOT_CORRECT: 'Пароль введен неправильно',
    SAVE_SUCCESSFULLY: 'Сохранено',
    AUTHORIZATION_SUCCESSFULLY: 'Успешная авторизация',
    EMAIL_OR_PASSWORD_INCORRECT: 'электронная почта или пароль неверны, пожалуйста, попробуйте еще раз',
    COMPANY_ADDED: 'Добавлена компания',
    ADDED_TO_CART: 'Добавлено в корзину',
    LEAVE_SUCCESS: 'Сохранено',
    SUCCESSFUL_PARTICIPATION: 'Мероприятие добавлено в Ваш список',
    SUCCESSFUL_JOIN: 'успешно присоединено',
    PAYMENT_PROCESS_FAIL: 'Обработка платежа не удалась',
    PAYMENT_PROCESS_SUCCESSFUL: 'Обработка платежа прошла успешно',
    PAYMENT_INVOICE_PROCESS_SUCCESSFUL:
      'Счет на оплату Вы можете скачать на странице Заказы. Это уведомление при оформлении заказа по счету',
    ERROR: 'Ошибка',
    DO_NOT_HAVE_TICKET: 'У вас пока нет билета',
    WRONG_DATA: 'Неверные данные',
    EMAIL_EXIST: 'Электронная почта уже была зарегистрирована. Пожалуйста, проверьте корректность введенной почты.',
    ALREADY_REGISTERED: 'Пользователь уже зарегистрирован',
    ALREADY_JOINED_PROJECT: 'Пользователь уже присоединился к этому проекту',
    ERROR_MESSAGE_NON_EXIST_EMAIL: 'Вы не зарегистрированы в системе',
    ERROR_MESSAGE_FORGOT_PASSWORD_EMAIL: 'Указанная почта не найдена',
    MESSAGE_SENT_PASSWORD_TO_EMAIL: 'Пароль отправлен на почту',
    FORCE_EDIT_CONFIRM:
      'Вы зарегистрированы. Если вы хотите отредактировать данные вашего профиля, вы потеряете все текущие подтвержденные регистрации. После редактирования необходимо заново пройти модерацию. Вы уверены, что хотите изменить профиль?',
    ARE_YOU_SURE: 'Вы уверены, что хотите выполнить это действие?',
    PLEASE_UPLOAD_LOGO: 'Пожалуйста, загрузите логотип',
    PLEASE_UPLOAD_AVATAR: 'Пожалуйста, загрузите аватар',
    CHOOSE_MEETING_SLOT: 'Выберите слот для встреч',
    AVAILABLE_FOR_MEETING: 'Доступен для встречи',
    TIME_AND_PLACE_OF_MEETING: 'Время и место встречи',
    CONFIRMED_MEETING: 'Ваш запрос был отправлен',
    SHOW_ALL_PARTICIPANTS: 'Показать всех участников',
    SLOT_BUSY_ERROR: 'У Вас уже есть слот в выбранной дате',
    NO_QUOTA: 'Превышена квота доступных встреч',
    DO_NOT_HAVE_APPOINTMENTS: 'У вас нет запланированных встреч',
    DO_NOT_HAVE_USER_VIZIT: 'У вас нет визита пользователя',
    DELETE_SUCCESSFULLY: 'Успешно удалено',
    LOGIN_WITH_YANDEX_ID: 'Войти с Яндекс ID',
    WAIT_UNTIL_ADMIN_SET_ROLE: 'Подождите, пока администратор не установит вашу роль',
    ALERT_REMOVE_USER_FROM_PROJECT: 'Вы уверены, что хотите удалить этого пользователя из этого проекта?',
    SHARE_MY_LINK: 'Поделись',
    VIZIT_NOT_FOUND: 'Визитка не найдена',
    YOU_CAN_ACCESS_AFTER_VERIFYING_YOUR_VIZITKA: 'Вы можете получить доступ после подтверждения вашей визитки',
    DOWNLOAD_SUCCESSFULLY: 'Скачено',
    DOWNLOAD_VCARD: 'VCard',
    ROWS_PER_PAGE: 'Рядов на странице',
    DELETE: 'Удалить',
    LOGIN_WARNING: 'Вход будет заблокирован на 30 минут после 5 попыток',
    LOGIN_ERROR: 'Вход заблокирован на 30 минут. Попробуйте позже',
    NO_MESSAGES: 'Нет сообщений',
    TYPE_MESSAGE: 'Можете написать другому участнику',
    ADDED_TO_CALENDAR: 'Добавлено в календарь',
    HIDE_MEETINGS: 'Скрыть встречи',
    SHOW_MEETINGS: 'Показать встречи',
    SET_UP_WILL_BE_DONE_SOON: 'Настройка будет выполнена в ближайшее время. Пожалуйста, попробуйте позже',
    YOU_HAVE_A_NEW_MESSAGE: 'У вас есть новое сообщение',
    YOU_HAVE_A_NEW_MEETING_BOOKING: 'У вас новое бронирование встречи',
    WARNING_CREATE_VIZIT: 'Создайте свою визитную карточку, чтобы получить доступ к некоторым функциям',
    GO_TO_BASKET: 'Перейти в корзину',
    CREATE_YOUR_BUSINESS_CARD_TO_BUY_A_PACKAGE:
      'Для отображения в общем каталоге компаний, необходимо заполнить визитную карточку и получить роль с необходимым функционалом. Детали можно уточнить в поддержке или у менеджера',
    WAIT_FOR_BUSINESS_CARD_CONFIRMATION: 'Дождитесь подтверждение визитную карточку или покупка пакета услуг',
    NO_PACKAGES: 'На данный момент пакетов и услуг нет',
    CAPTCHA_ERROR: 'Вам необходимо еще раз подтвердить капчу',
    VERIFY_SUCCESSFULLY: 'Подтвердить успешно',
    VERIFY_CODE_IS_INVALID_OR_EXPRIED: 'Код неверен или срок его действия истек',
    REGISTRATION_IS_NOT_AVAILABLE: 'Регистрация не доступна',
    PLEASE_CONTACT_THE_CHECK_IN_DESK: 'для получения билета обратитесь на стойку регистрации',
    ALERT_CONTINUE_FILL_OUT_THE_PAGE: 'Продолжите заполнять или страница будет закрыта через {second} секунд',
    YOUR_TICKET_HAS_BEEN_SENT: 'Ваш билет отправлен на печать',
    GENERATE_YOUR_TICKET: 'Создайте свой билет',
    NO_DATA: 'Нет данных',
    CHOOSE_ONE_OF_BELOW_OPTIONS: 'Выберите один из вариантов ниже',
    I_AM_A_NEW_VISITOR: 'Я новый посетитель',
    I_ALREADY_HAVE_REGISTRATION: 'У меня уже есть билет',
    ENTER_YOUR_TICKET_NUMBER: 'Введите номер вашего билета',
    YOUR_TICKET_HAS_BEEN_SENT_WITHOUT_EMAIL: 'Ваш билет отправлен на печать',
    YOUR_TICKET_HAS_NOT_FOUND: 'Ваш билет не найден',
    ALL_PACKAGES_WERE_DISTRIBUTED: 'Вы уже распределили все пакеты',
    PLEASE_SELECT_A_ROLE: 'Выберите роль, пожалуйста',
    THIS_WEBSITE_USES_COOKIES:
      'Мы используем cookie-файлы и другие аналогичные технологии. Пользуясь данным сайтом, Вы не возражаете против использования этих технологий',
    FILE_SIZE_IS_TOO_LARGE: 'Размер Файл слишком большой',
    YOUR_YANDEX_ACCOUNT_DOES_NOT_HAVE_EMAIL: 'В вашем аккаунте яндекс нет почты. Попробуйте другой аккаунт, пожалуйста',
    CONFIRM_LEAVE_PROJECT: 'Покинув проект вы удалите все данные связанные с этим проектом. Вы уверены?',
    ENTER_YOUR_EMAIL: 'Введите Ваш e-mail',
    EVENT_PARTICIPANT: 'участника мероприятия',
    TO_REGISTER_FOR_THE_EVENT: 'Для регистрации на мероприятии необходимо войти',
    OR_REGISTER_YOUR_ACCOUNT: 'или зарегистрироваться в личном кабинете',
    REGISTER_REACH_LIMIT_NOTIFY_1:
      'Регистрация по данной ссылке ограничена. Для регистрации в Личном кабинете вы можете перейти по ссылке - ',
    REGISTER_REACH_LIMIT_NOTIFY_2: ' и зарегистрироваться как посетитель, или обратитесь в службу поддержки ',
    GO_TO_HOME: 'Идти домой',
  },
};
